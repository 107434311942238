import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  TableHeader,
  TableBody,
  TableColumn,
  TableRow,
  TableCell,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Button,
  Select,
  SelectItem,
  Skeleton,
  useDisclosure,
} from "@nextui-org/react";
import React from "react";
import { useTranslation } from "react-i18next";

import { useAuth } from "@wc/domain/authn";
import { PermissionsEnum, userHasPermissionInOrg } from "@wc/domain/authz";
import { formatDate } from "@wc/domain/dates";
import { useOrg } from "@wc/domain/organizations";
import { ReactComponent as SettingIcon } from "@wc/ui/assets/icons/setting.svg";

import { OrgRoles } from "../constants";
import {
  listOrgMembers,
  removeOrgMember,
  updateOrgMemberRole,
} from "../core/members";

/**
 * @param {object} props The component props
 * @param {boolean} [props.readOnly] If readOnly is false, then this will do permission checks. Defaults to true.
 * @returns {React.ReactElement} List of the organization members
 */
export default function OrgMemberList({ readOnly = true }) {
  const { t } = useTranslation();
  const [members, setMembers] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [selectedMembers, setSelectedMembers] = React.useState(new Set());
  const [hasManagePermission, setHasManagePermission] = React.useState(false);
  const [newMemberRole, setNewMemberRole] = React.useState(new Set([]));
  const orgData = useOrg();
  const userData = useAuth();
  const {
    isOpen: isRemoveModalOpen,
    onOpen: onRemoveModalOpen,
    onOpenChange: onRemoveModalOpenChange,
  } = useDisclosure();
  const {
    isOpen: isRoleModalOpen,
    onOpen: onRoleModalOpen,
    onOpenChange: onRoleModalOpenChange,
  } = useDisclosure();

  React.useEffect(() => {
    const fetchMembers = async () => {
      try {
        const membersList = await listOrgMembers(
          orgData?.org.id,
          userData.getCurrentUser().id,
        );
        setMembers(membersList);
      } catch (error) {
        console.error("Failed to fetch members:", error);
      }
      setIsLoading(false);
    };

    const fetchPermissions = async () => {
      if (readOnly) {
        setHasManagePermission(false);
        return;
      }

      try {
        const hasPerm = await userHasPermissionInOrg(
          userData.getCurrentUser(),
          orgData?.org.id,
          PermissionsEnum.MANAGE_ORGANIZATION_MEMBERS,
        );
        setHasManagePermission(hasPerm);
      } catch {
        setHasManagePermission(false);
      }
    };

    if (orgData?.org?.id && userData.getCurrentUser()) {
      fetchMembers();
      fetchPermissions();
    }
  }, [orgData?.org?.id, userData, readOnly]);

  const handleRemoveMember = async (memberId) => {
    try {
      setIsLoading(true);
      await removeOrgMember(
        orgData?.org?.id,
        memberId,
        userData.getCurrentUser(),
      );
      setMembers(members.filter((member) => member.id !== memberId));
      setSelectedMembers(new Set());
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const handleChangeRole = async (member) => {
    if (newMemberRole.size === 0) {
      setNewMemberRole(new Set([]));
      return;
    }
    const newRole = Array.from(newMemberRole)[0];
    if (newRole === member.role) {
      setNewMemberRole(new Set([]));
      return;
    }

    try {
      setIsLoading(true);
      await updateOrgMemberRole(
        orgData?.org?.id,
        member.id,
        newRole,
        userData.getCurrentUser(),
      );

      setMembers((prevMembers) =>
        prevMembers.map((m) =>
          m.id === member.id ? { ...m, role: newRole } : m,
        ),
      );

      setSelectedMembers(new Set());
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
    setNewMemberRole(new Set([]));
  };

  return (
    <Table
      aria-label="Organization members table"
      selectionMode={hasManagePermission ? "multiple" : "none"}
      selectedKeys={selectedMembers}
      onSelectionChange={setSelectedMembers}
    >
      <TableHeader>
        <TableColumn>Name</TableColumn>
        <TableColumn>Created</TableColumn>
        <TableColumn>Role</TableColumn>
        <TableColumn></TableColumn>
      </TableHeader>
      <TableBody>
        {isLoading
          ? Array(3)
              .fill()
              .map((_, index) => (
                <TableRow key={`loading-row-${index}`}>
                  <TableCell>
                    <Skeleton className="h-3 w-3/4" />
                  </TableCell>
                  <TableCell>
                    <Skeleton className="h-3 w-1/2" />
                  </TableCell>
                  <TableCell>
                    <Skeleton className="h-3 w-1/4" />
                  </TableCell>
                  <TableCell>
                    <Skeleton className="h-3 w-8" />
                  </TableCell>
                </TableRow>
              ))
          : members.map((member) => (
              <TableRow key={member.id}>
                <TableCell>{member.displayName}</TableCell>
                <TableCell>{formatDate(member.createdAt)}</TableCell>
                <TableCell>{member.role}</TableCell>
                <TableCell>
                  {hasManagePermission &&
                    userData.getCurrentUser().id !== member.id &&
                    selectedMembers.size <= 1 && (
                      <>
                        <Dropdown>
                          <DropdownTrigger>
                            <Button isIconOnly variant="light">
                              <SettingIcon role="img" aria-label="settings" />
                            </Button>
                          </DropdownTrigger>
                          <DropdownMenu>
                            <DropdownItem onClick={onRemoveModalOpen}>
                              {t("orgMembership.removeMember")}
                            </DropdownItem>
                            <DropdownItem onClick={onRoleModalOpen}>
                              {t("orgMembership.changeRole")}
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                        <Modal
                          isOpen={isRemoveModalOpen}
                          onOpenChange={onRemoveModalOpenChange}
                        >
                          <ModalContent>
                            <ModalHeader className="flex flex-col gap-1">
                              {t("orgMembership.removeModalHeader")}
                            </ModalHeader>
                            <ModalBody>
                              <p>
                                {t("orgMembership.removeModalBody", {
                                  name: member.displayName,
                                })}
                              </p>
                            </ModalBody>
                            <ModalFooter>
                              <Button
                                variant="light"
                                onPress={onRemoveModalOpenChange}
                              >
                                {t("cancel")}
                              </Button>
                              <Button
                                color="danger"
                                onPress={() => {
                                  handleRemoveMember(member.id);
                                  onRemoveModalOpenChange();
                                }}
                              >
                                {t("orgMembership.removeModalConfirm")}
                              </Button>
                            </ModalFooter>
                          </ModalContent>
                        </Modal>
                        <Modal
                          isOpen={isRoleModalOpen}
                          onOpenChange={onRoleModalOpenChange}
                        >
                          <ModalContent>
                            <ModalHeader className="flex flex-col gap-1">
                              {t("orgMembership.roleModalHeader")}
                            </ModalHeader>
                            <ModalBody>
                              <Select
                                disallowEmptySelection={true}
                                defaultSelectedKeys={[member.role]}
                                onSelectionChange={setNewMemberRole}
                                aria-label="Select role"
                              >
                                {OrgRoles.map((or) => (
                                  <SelectItem key={or} textValue={or}>
                                    {or}
                                  </SelectItem>
                                ))}
                              </Select>
                            </ModalBody>
                            <ModalFooter>
                              <Button
                                variant="light"
                                onPress={onRoleModalOpenChange}
                              >
                                {t("cancel")}
                              </Button>
                              <Button
                                color="primary"
                                onPress={() => {
                                  handleChangeRole(member);
                                  onRoleModalOpenChange();
                                }}
                              >
                                {t("orgMembership.roleModalConfirm")}
                              </Button>
                            </ModalFooter>
                          </ModalContent>
                        </Modal>
                      </>
                    )}
                </TableCell>
              </TableRow>
            ))}
      </TableBody>
    </Table>
  );
}
